import isNil from "lodash/isNil";

export const slugify = (str: string) => {
  if (isNil(str) || !str?.length) {
    return str;
  }

  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};
