import React from "react";
import cn from "classnames";

interface IBadgeProps {
  className?: string;
}

const Badge: React.FC<IBadgeProps> = ({ className, children }) => (
  <div
    className={cn(
      "w-3 h-3 bg-red-600 rounded-full flex items-center justify-center overflow-hidden",
      className
    )}
  >
    {children}
  </div>
);

export default Badge;
