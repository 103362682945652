const sanitizeName = (name: string) => {
  if (!name?.length) return name;

  let sanitized = name.toLowerCase();
  if (name.startsWith("t_")) {
    sanitized = sanitized.replace("t_", "");
  }

  return sanitized
    .replace("_pricematch", "")
    .replace("fw_", "")
    .split("_")
    .join(" ")
    .trim()
    .toLowerCase();
};
export default sanitizeName;
