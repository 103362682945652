import React from "react";
import { Range as ReactRange, getTrackBackground } from "react-range";
import cn from "classnames";
import { theme } from "tailwind.config";
import { useState } from "react";

const Range = ({
  min,
  max,
  step = 1,
  value,
  onChange,
  onFinalChange,
  spinner,
  disabled,
  hideValues,
  formatter,
  trackStyle,
  barStyle,
  direction,
  trackColors = ["#ccc", "#323233", "#ccc"],
  hoverTrackColors = ["#ccc", theme.extend.colors.indigo[600], "#ccc"],
  thumbColor,
}: any) => {
  const [hover, setHover] = useState(false);

  if (spinner) return <>loading</>;

  return (
    <div>
      <ReactRange
        disabled={disabled}
        step={step}
        min={min}
        max={max}
        values={value}
        direction={direction}
        onFinalChange={onFinalChange}
        onChange={onChange}
        renderTrack={({ props, children }: any) => {
          return (
            <div
              className="group relative"
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              onPointerOver={() => setHover(true)}
              onPointerOut={() => setHover(false)}
              style={{
                ...props.style,
                height: "4px",
                marginTop: "5px",

                display: "flex",
                width: "100%",
                ...trackStyle,
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: disabled
                    ? `${theme.extend.colors.gray[200]}!important`
                    : hover
                    ? getTrackBackground({
                        values: value,
                        colors: hoverTrackColors.slice(0, value.length + 1),
                        min,
                        max,
                      })
                    : getTrackBackground({
                        values: value,
                        colors: trackColors.slice(0, value.length + 1),
                        min,
                        max,
                      }),
                  alignSelf: "center",
                  ...barStyle,
                }}
              >
                {children}
              </div>
            </div>
          );
        }}
        renderThumb={({ props }: any) => {
          return (
            <div
              {...props}
              className={cn(
                "relative w-4 h-4 transform translate-x-10 bg-gray-900 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 group-hover:bg-cta-600 group-hover:shadow-[0_1px_5px_rgba(0,0,0,0.15)]",
                disabled && "!bg-gray-200 !text-gray-400",
                thumbColor && thumbColor
              )}
            >
              {hideValues ? null : (
                <div className="absolute z-10 top-5 text-13 left-0 w-4 flex items-center justify-center">
                  {formatter ? formatter(value[props.key]) : value[props.key]}
                </div>
              )}
            </div>
          );
        }}
      />
      {/* <div className="flex justify-between">
        <div>{values[0] < range * 0.15 ? null : min}</div>
        <div>{values[0] > range * 0.85 ? null : max}</div>
      </div> */}
    </div>
  );
};

export default Range;

export const OneSideRange = (props: any) => (
  <Range
    {...props}
    value={[props.value !== undefined ? props.value : 0]}
    onChange={(values: any) =>
      props.onChange(props.mapValue ? props.mapValue(values[0]) : values[0])
    }
    trackColors={props.trackColors || ["#323233", "#ccc"]}
    hoverTrackColors={
      props.hoverTrackColors || [theme.extend.colors.indigo[600], "#ccc"]
    }
    thumbColor={props.thumbColor}
  />
);
