export const beautifyNumber = (num: number) => {
  if (!num) {
    return num;
  }
  return num?.toFixed(2);
};

export const maxDigitsAfterDot = (num, digits = 2) => {
  return Math.trunc(num * 10 ** digits) / 10 ** digits;
};

export const beautifyBigNumber = (number: number) => {
  if (number === undefined) {
    return;
  }
  const unsignedNumber = Math.abs(number);

  if (unsignedNumber < 1000) return number;

  if (unsignedNumber < 1000000) {
    return `${Math.round(unsignedNumber / 100) / 10}K`;
  }

  if (unsignedNumber < 1000000000) {
    return `${Math.round(unsignedNumber / 100000) / 10}M`;
  }

  if (unsignedNumber < 1000000000000) {
    return `${Math.round(unsignedNumber / 10000000) / 10}B`;
  }

  return `${Math.round(unsignedNumber / 1000000000) / 10}T`;
};

export const convertUSLocalString = (number: string | number) =>
  number.toLocaleString("en-US");
