import React, { Fragment } from "react";
import Icon from "./Icon";
import cn from "classnames";
import { Listbox, Transition } from "@headlessui/react";
import ChevronDown from "public/icons/chevron_down.svg";
import useTranslation from "next-translate/useTranslation";
import capitalize from "lodash/capitalize";
import { useState } from "react";
import { useMemo } from "react";
import Input from "./Input";
import { ISelectValue } from "types";
import InfoTooltip from "./InfoTooltip";
import CheckIcon from "public/icons/checkmark.svg";
import Tag from "components/atoms/Tag";
import isArray from "lodash/isArray";
import { useRef } from "react";

export interface ISelectProps {
  options: any[];
  value: any;
  onSelect: any;
  className?: string;
  gray?: boolean;
  label?: string;
  errorMassage?: string;
  buttonClassName?: string;
  optionsContainerClassName?: string;
  placeholder?: string;
  textClassName?: string;
  before?: React.FC<any>;
  icon?: any;
  containerClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  small?: boolean;
  slim?: boolean;
  search?: boolean;
  required?: boolean;
  multiple?: boolean;
  onDeleteSelected?: (val: ISelectValue) => void;
  labelTip?: string;
  labelTipClass?: string;
  color?: boolean;
  compareBy?: string;
  optional?: boolean;
  valueAsTag?: boolean;
  placeholderClassName?: string;
  boldLabel?: boolean;
}

const Select = ({
  options,
  value,
  onSelect,
  className = "",
  label,
  errorMassage,
  buttonClassName,
  optionsContainerClassName,
  placeholder,
  textClassName,
  before: BeforeComponent,
  containerClassName = "",
  icon,
  labelClassName,
  disabled,
  small,
  slim,
  search,
  required,
  multiple,
  labelTip,
  labelTipClass,
  placeholderClassName,
  color,
  compareBy,
  optional,
  valueAsTag,
  onDeleteSelected,
  boldLabel,
}: ISelectProps) => {
  const { t } = useTranslation("common");
  const [searchValue, setSearchValue] = useState<string>("");
  const searchRef = useRef<HTMLInputElement>(null);

  const filteredOptions = useMemo(() => {
    if (searchValue) {
      return options.filter((el) =>
        el.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return options;
  }, [options, searchValue]);

  const displayValue = useMemo(() => {
    if (multiple) {
      const transformedValue = value?.length
        ? value?.map((el: ISelectValue) =>
            valueAsTag ? (
              <Tag
                onDelete={
                  onDeleteSelected
                    ? (e: any) => {
                        e.stopPropagation();
                        onDeleteSelected(el);
                      }
                    : null
                }
                className="mr-1 bg-gray-40 !px-2 !py-0.5"
              >
                {t(el?.name)}
              </Tag>
            ) : (
              t(el?.name)
            )
          )
        : placeholder;

      return valueAsTag
        ? transformedValue
        : isArray(transformedValue) && transformedValue?.length
        ? transformedValue.join(", ")
        : transformedValue;
    }

    return value?.name ? (
      valueAsTag ? (
        <Tag
          onDelete={
            onDeleteSelected
              ? (e: any) => {
                  e.stopPropagation();
                  onDeleteSelected(value);
                }
              : null
          }
          className="mr-1 bg-gray-40 !px-2 !py-0.5"
        >
          {t(value?.name)}
        </Tag>
      ) : (
        t(value?.name)
      )
    ) : (
      placeholder
    );
  }, [multiple, value, placeholder, valueAsTag]);

  return (
    <div className={cn("flex flex-col relative", containerClassName)}>
      {label && (
        <label
          className={cn(
            "label-100 relative w-fit mb-1",
            boldLabel && "body-100 font-semibold",
            small && "text-xs leading-none font-normal mb-2",
            labelClassName
          )}
        >
          <div className="flex items-center relative">
            <div className="inline">
              {capitalize(label)}
              {required && (
                <span className="text-cobalt-400 absolute top-0 -right-2 text-xs">
                  {" "}
                  *
                </span>
              )}
              {optional && (
                <span className="body-100-subtle text-gray-50 font-semibold">{` - ${t(
                  "optional"
                )}`}</span>
              )}
            </div>
            {!!labelTip && (
              <InfoTooltip
                dataTip={labelTip}
                className={cn("ml-2 min-w-[100px] w-fit", labelTipClass)}
                tipClassName={"whitespace-pre-line"}
              />
            )}
          </div>
        </label>
      )}
      <Listbox
        disabled={disabled}
        value={value}
        by={compareBy}
        onChange={onSelect}
        className={className}
        multiple={multiple}
      >
        {({ open }) => {
          if (open && search && searchRef?.current) {
            searchRef.current.focus();
          }

          return (
            <div className="relative">
              <Listbox.Button
                disabled={disabled}
                className={cn(
                  "p-4 py-[11px] max-h-10 flex items-center justify-between border border-border-normal group disabled:bg-gray-100 disabled:border-gray-200 disabled:text-gray-400 hover:bg-gray-10 rounded-md body-100",
                  {
                    "shadow-[0_0_0_1px_rgb(31_31_31)] border-border-focus":
                      open,
                    "border-border-error": errorMassage,
                    "!max-h-fit": valueAsTag,
                    "border-0 !bg-gray-100 !py-2 px-4 hover:!bg-indigo-600 rounded-3xl":
                      small,
                    "border-none !rounded-full !bg-white px-4 py-1.5 shadow-[0_2px_8px_rgba(0,0,0,0.1)]":
                      slim,
                  },
                  buttonClassName
                )}
              >
                <div className="flex items-center max-w-[90%]">
                  {icon && icon}
                  {BeforeComponent && <BeforeComponent />}
                  <div
                    className={cn(
                      "block mr-2 text-sm text-gray-900 overflow-hidden",
                      (multiple ? !value?.length : !value?.name) &&
                        cn("text-gray-50", placeholderClassName),
                      !valueAsTag ? "truncate" : "text-left",
                      small &&
                        "!text-xs group-hover:text-white group-disabled:!text-gray-400",
                      icon && "ml-2",
                      search &&
                        valueAsTag &&
                        "flex items-center flex-wrap gap-1",
                      textClassName
                    )}
                  >
                    {color ? (
                      <>
                        {!!value ? (
                          <div className="flex items-center">
                            <div
                              style={{ background: value.value }}
                              className={cn(
                                "w-4 h-4 border border-border-normal rounded-full mr-1",
                                !value.value && "border-0"
                              )}
                            />
                            {value.name}
                          </div>
                        ) : (
                          placeholder
                        )}
                      </>
                    ) : (
                      <>
                        {search && valueAsTag
                          ? (isArray(value) ? !!value?.length : !!value) &&
                            displayValue
                          : displayValue}
                        {search && valueAsTag && (
                          <Input
                            inputRef={searchRef}
                            placeholder={
                              (isArray(value) ? !!value?.length : !!value)
                                ? ""
                                : placeholder
                            }
                            onChange={(e: any) =>
                              setSearchValue(e.target.value)
                            }
                            value={searchValue}
                            containerClassName="pb-0"
                            fieldClassName="!p-0 !border-none text-gray-950 bg-transparent !h-auto"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <Icon
                  component={ChevronDown}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  className={cn("group-disabled:fill-gray-200", {
                    "group-disabled:!fill-gray-200": small,
                    "group-hover:!fill-white": small,
                  })}
                />
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={cn(
                    "absolute z-10 top-8 mt-2 p-2 overflow-auto body-100 bg-white rounded-md border border-border-normal max-h-60 focus:outline-none w-full",
                    optionsContainerClassName
                  )}
                >
                  {!filteredOptions?.length && <p>{t("no_options")}</p>}
                  {filteredOptions?.map((item, itemIdx) => (
                    <Listbox.Option
                      key={itemIdx}
                      className={({ active }) =>
                        cn(
                          "cursor-pointer flex justify-between items-center gap-4 body-100 select-none relative p-2 hover:bg-gray-20 hover:text-typography-headline rounded-md",
                          active ? "text-indigo-70-600" : "text-gray-900"
                        )
                      }
                      value={item}
                    >
                      {({ selected }) => (
                        <>
                          <span className="block truncate">{t(item.name)}</span>
                          {selected && (
                            <Icon
                              component={CheckIcon}
                              viewBox="0 0 16 17"
                              className="!fill-gray-950  shrink-0"
                            />
                          )}
                          {color && (
                            <div
                              style={{ background: item.value }}
                              className={cn(
                                "w-4 h-4 border border-border-normal rounded-full shrink-0",
                                !item.value && "border-0"
                              )}
                            />
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          );
        }}
      </Listbox>
      {errorMassage && (
        <div className="error-100 absolute -bottom-[17px]">{errorMassage}</div>
      )}
    </div>
  );
};

export default Select;
