import React from "react";
import cn from "classnames";
import RadioButton, { IRadioOption } from "components/atoms/RadioButton";

interface IRadioButtonGroupProps {
  options: Array<IRadioOption>;
  name: string;
  buttonClassName?: string;
  className?: string;
  onChange: (e: any) => void;
}

const RadioButtonGroup: React.FC<IRadioButtonGroupProps> = ({
  options,
  name,
  buttonClassName,
  className,
  onChange,
}) => {
  const onButtonClick = (option: IRadioOption) => {
    onChange({
      name,
      options: options.map((item) => ({
        ...item,
        checked: item.value === option.value,
      })),
    });
  };

  return (
    <div className={cn("flex items-center", className)}>
      {options.map((option, index) => (
        <RadioButton
          key={option.label + index}
          option={option}
          groupName={name}
          className={cn("mr-2 ", buttonClassName)}
          onChange={onButtonClick}
        />
      ))}
    </div>
  );
};

export default RadioButtonGroup;
