import { slugify } from "./slugify";

export const getProductImageUrl = (
  brand: string = "",
  name: string = "",
  id: string = ""
) =>
  `https://bcdn-images.hotdata.cc/images/products/${slugify(brand)}-${slugify(
    name
  )}-${id}.jpg`;

export const getProductImageUrlByJbid = (jbid: string = "") => [
  `https://cdn.hotdata.cc/img/${jbid.slice(0, 2)}/${jbid}_01.jpg`,
  `https://bcdn.hotdata.cc/img/${jbid.slice(0, 2)}/${jbid}_01.jpg`,
  `https://bcdn.hotdata.cc/img/s/${jbid.slice(2, 5)}/${jbid}_01.jpg`,
];

export const getProxyImage = (imageUrl?: string) => {
  if (!imageUrl) return "";

  return `/api/imageProxy?imageUrl=${imageUrl}`;
};
