import React from "react";
import cn from "classnames";
import Close from "public/icons/close.svg";
import Icon from "./Icon";
import { theme } from "tailwind.config";

const Tag = ({
  active,
  onClick,
  onDelete,
  className,
  children,
  style,
}: {
  onClick?: Function;
  onDelete?: Function | null;
  active?: boolean;
  className?: string;
  children: any;
  style?: any;
}) => {
  const classNameStr = cn(
    "py-0.5 px-2 rounded-full bg-gray-40 text-gray-950 text-xs mr-1 box-border inline-flex items-center",
    onClick
      ? "cursor-pointer hover:bg-indigo-200 hover:text-cta-600"
      : "cursor-auto",
    active && "bg-indigo-50 border border-indigo-350 py-[6px] px-[14px]",
    className
  );
  return (
    <div
      style={style}
      className={classNameStr}
      onClick={(e) => (onDelete ? onDelete(e) : onClick && onClick(e))}
    >
      {children}
      {onDelete && (
        <Icon
          component={Close}
          viewBox="0 0 12 12"
          stroke={theme.extend.colors.gray[900]}
          className="ml-2 cursor-pointer shrink-0"
        />
      )}
    </div>
  );
};

export default Tag;
