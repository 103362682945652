import React from "react";
import cn from "classnames";
import Icon, { IconProps } from "./Icon";
import useTranslation from "next-translate/useTranslation";

export interface IRadioOption {
  label: string;
  value: any;
  checked: boolean;
  icon?: React.ReactNode;
  iconProps?: Partial<IconProps>;
  id?: string | number;
}

interface IRadioButtonProps {
  option: IRadioOption;
  onChange: (e: any) => void;
  className?: string;
  groupName?: string;
  disabled?: boolean;
}

const RadioButton: React.FC<IRadioButtonProps> = ({
  option,
  onChange,
  className,
  groupName,
  disabled,
}) => {
  const { t } = useTranslation("common");
  const onButtonClick = () => {
    onChange(option);
  };

  return (
    <label
      className={cn(
        "flex items-center text-13 font-bold cursor-pointer group",
        disabled && "cursor-default",
        className
      )}
      htmlFor={option.id ? option.id.toString() : option.label}
    >
      <input
        type="radio"
        className="appearance-none cursor-pointer group-hover:ring-cta-600 group-hover:checked:bg-cta-600 disabled:cursor-default group-hover:disabled:ring-gray-400 group-hover:disabled:bg-gray-200 group-hover:disabled:checked:bg-gray-400 ring-1 ring-gray-600 disabled:ring-gray-400 disabled:bg-gray-200 disabled:checked:ring-gray-400 disabled:checked:border-gray-200 disabled:checked:bg-gray-400 checked:ring-gray-900 mr-2 rounded-full w-4 h-4 checked:border-4 checked:border-white checked:bg-gray-900"
        id={option.id ? option.id.toString() : option.label}
        name={groupName}
        checked={option.checked}
        onChange={onButtonClick}
        disabled={disabled}
      />
      {!!option.icon && (
        <Icon
          component={option.icon}
          className={
            option.iconProps
              ? option.iconProps?.className
              : "stroke-black group-hover:stroke-indigo-600 mr-2"
          }
          viewBox={option.iconProps?.viewBox || "0 0 20 20"}
        />
      )}
      <span
        className={cn(
          "group-hover:text-cta-600 whitespace-nowrap",
          disabled && "text-gray-600 group-hover:text-gray-600"
        )}
      >
        {t(option.label)}
      </span>
    </label>
  );
};

export default RadioButton;
